import React from "react";
import "./App.css";

class App extends React.Component {
  componentDidMount() {
    window.location.replace("https://www.lunchwith.me/amazing/natascha");
  }

  render() {
    return (
      <div className="container App-spinner">
        <div className="row mt-5">
          <div className="col-12 text-center mt-5">
            <div className="spinner-border text-info">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
